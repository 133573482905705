import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { AIEvaluation } from '@/lib/types/reports'

const METRIC_COLORS = {
  accuracy: 'from-emerald-500 to-emerald-600',
  helpfulness: 'from-blue-500 to-blue-600',
  clarity: 'from-violet-500 to-violet-600',
  responseTime: 'from-amber-500 to-amber-600',
}

interface AIPerformanceCardProps {
  data: AIEvaluation
}

export function AIPerformanceCard({ data }: AIPerformanceCardProps) {
  const { totalStudents, activeStudents, ...metrics } = data
  
  return (
    <Card className="col-span-2 bg-gradient-to-br from-slate-50 to-white dark:from-slate-900 dark:to-slate-800">
      <CardHeader>
        <CardTitle className="flex items-center justify-between">
          <span>AI Performance Metrics</span>
          <span className="rounded-full bg-blue-100 px-3 py-1 text-sm font-medium text-blue-600 dark:bg-blue-900 dark:text-blue-200">
            {activeStudents} active / {totalStudents} total students
          </span>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-2 gap-4">
          {Object.entries(metrics).map(([key, value]) => (
            <div key={key} className="space-y-2">
              <p className="text-sm font-medium capitalize">{key.replace(/([A-Z])/g, ' $1')}</p>
              <div className="flex items-center gap-2">
                <div className="h-3 flex-1 rounded-full bg-slate-200 dark:bg-slate-700">
                  <div
                    className={`h-full rounded-full bg-gradient-to-r ${METRIC_COLORS[key as keyof typeof METRIC_COLORS]} transition-all`}
                    style={{ width: `${value}%` }}
                  />
                </div>
                <span className="text-sm font-bold">{value}%</span>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  )
}