import { useState, useEffect } from 'react'
import { useAuth } from '@/components/auth/AuthProvider'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { supabase } from '@/lib/supabase'

export function ProfileSection() {
  const { user } = useAuth()
  const [email, setEmail] = useState(user?.email || '')
  const [error, setError] = useState('')
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    if (user) {
      console.log('Current user:', user)
      console.log('User metadata:', user.user_metadata)
      console.log('App metadata:', user.app_metadata)
    }
  }, [user])

  const handleUpdateProfile = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!user) return

    setIsUpdating(true)
    setError('')

    try {
      const { error } = await supabase.auth.updateUser({ 
        email,
        data: {
          updated_at: new Date().toISOString(),
        }
      })
      if (error) throw error
    } catch (error) {
      setError('Failed to update profile')
    } finally {
      setIsUpdating(false)
    }
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Profile</CardTitle>
        <CardDescription>
          Manage your profile information
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <div className="flex items-center space-x-4">
          <Avatar className="h-16 w-16">
            <AvatarImage src={user?.user_metadata?.avatar_url} />
            <AvatarFallback>
              {user?.email?.[0]?.toUpperCase()}
            </AvatarFallback>
          </Avatar>
          <div>
            <h3 className="font-medium">{user?.user_metadata?.full_name || 'User'}</h3>
            <p className="text-sm text-muted-foreground">{user?.email}</p>
            {user?.user_metadata?.user_type && (
              <p className="text-sm text-muted-foreground">Type: {user.user_metadata.user_type}</p>
            )}
            {user?.user_metadata?.signup_source && (
              <p className="text-sm text-muted-foreground">Source: {user.user_metadata.signup_source}</p>
            )}
          </div>
        </div>

        <div className="space-y-2">
          <h4 className="font-medium">Metadata</h4>
          <div className="rounded-md bg-muted p-4">
            <pre className="text-xs overflow-auto">
              {JSON.stringify(user?.user_metadata, null, 2)}
            </pre>
          </div>
        </div>

        <form onSubmit={handleUpdateProfile} className="space-y-4">
          <div className="space-y-2">
            <label htmlFor="email" className="text-sm font-medium">
              Email
            </label>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
          </div>

          {error && (
            <p className="text-sm text-red-500">{error}</p>
          )}

          <Button type="submit" disabled={isUpdating}>
            {isUpdating ? 'Updating...' : 'Update Profile'}
          </Button>
        </form>
      </CardContent>
    </Card>
  )
}