import { useState, useCallback } from 'react'
import { STORAGE_KEYS } from '../constants/storage-keys'

interface ApiKeys {
  nexusHost: string
  nexusSecret: string
}

export function useApiKeys() {
  const [keys, setKeys] = useState<ApiKeys>(() => ({
    nexusHost: localStorage.getItem(STORAGE_KEYS.NEXUS_HOST) || '',
    nexusSecret: localStorage.getItem(STORAGE_KEYS.NEXUS_SECRET) || '',
  }))

  const saveKeys = useCallback(async (newKeys: ApiKeys) => {
    try {
      localStorage.setItem(STORAGE_KEYS.NEXUS_HOST, newKeys.nexusHost)
      localStorage.setItem(STORAGE_KEYS.NEXUS_SECRET, newKeys.nexusSecret)
      setKeys(newKeys)
      return true
    } catch (error) {
      console.error('Failed to save API keys:', error)
      return false
    }
  }, [])

  return {
    keys,
    saveKeys,
  }
}