import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Users } from 'lucide-react'
import { mockAgentReports } from '@/lib/data/mock-reports'
import { AgentMetricsCard } from '@/components/reports/AgentMetricsCard'
import { QuestionTypeChart } from '@/components/reports/QuestionTypeChart'
import { AIPerformanceCard } from '@/components/reports/AIPerformanceCard'
import { SchoolSummary } from '@/components/reports/SchoolSummary'

export function ReportsPage() {
  const reports = mockAgentReports

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-3xl font-bold tracking-tight">Reports</h2>
        <p className="text-muted-foreground mt-2">
          Agent performance and student engagement analytics.
        </p>
      </div>

      <SchoolSummary data={reports} />

      <Card className="bg-gradient-to-br from-blue-50 to-indigo-50 dark:from-blue-950 dark:to-indigo-950">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-lg font-medium">Overall Student Participation</CardTitle>
          <Users className="h-5 w-5 text-blue-500" />
        </CardHeader>
        <CardContent>
          <div className="grid gap-4 md:grid-cols-3">
            <div className="rounded-lg bg-white p-4 shadow-lg dark:bg-slate-800">
              <p className="text-sm text-blue-600 dark:text-blue-400">Total Students</p>
              <p className="text-2xl font-bold">{reports.totalStudents}</p>
            </div>
            <div className="rounded-lg bg-white p-4 shadow-lg dark:bg-slate-800">
              <p className="text-sm text-emerald-600 dark:text-emerald-400">Active Students</p>
              <p className="text-2xl font-bold">{reports.activeStudents}</p>
            </div>
            <div className="rounded-lg bg-white p-4 shadow-lg dark:bg-slate-800">
              <p className="text-sm text-violet-600 dark:text-violet-400">Participation Rate</p>
              <p className="text-2xl font-bold">
                {Math.round((reports.activeStudents / reports.totalStudents) * 100)}%
              </p>
            </div>
          </div>
        </CardContent>
      </Card>

      {reports.agents.map((agent) => (
        <div key={agent.agentId} className="space-y-4">
          <AgentMetricsCard
            agentName={agent.agentName}
            metrics={[
              { 
                label: 'Total Questions', 
                value: agent.totalQuestions,
                type: 'info'
              },
              { 
                label: 'Unique Students', 
                value: agent.uniqueStudents,
                type: 'success'
              },
              { 
                label: 'Student Participation', 
                value: `${Math.round((agent.uniqueStudents / reports.totalStudents) * 100)}%`,
                description: `${agent.uniqueStudents} out of ${reports.totalStudents} total students`,
                type: 'warning'
              },
              { 
                label: 'Active Rate', 
                value: `${Math.round((agent.studentEngagement.activeStudents / agent.studentEngagement.totalStudents) * 100)}%`,
                description: `${agent.studentEngagement.activeStudents} active students`,
                type: 'success'
              },
            ]}
          />

          <div className="grid gap-4 md:grid-cols-2">
            <QuestionTypeChart 
              data={agent.questionTypes} 
              totalStudents={reports.totalStudents}
            />
            <AIPerformanceCard data={agent.aiEvaluation} />
          </div>

          <Card className="bg-gradient-to-br from-slate-50 to-white dark:from-slate-900 dark:to-slate-800">
            <CardHeader>
              <CardTitle>Top Discussion Topics</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {agent.topTopics.map((topic, index) => (
                  <div key={topic.topic} className="flex items-center justify-between">
                    <span className={`rounded-full px-3 py-1 text-sm font-medium
                      ${index % 4 === 0 ? 'bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-200' :
                        index % 4 === 1 ? 'bg-emerald-100 text-emerald-700 dark:bg-emerald-900 dark:text-emerald-200' :
                        index % 4 === 2 ? 'bg-violet-100 text-violet-700 dark:bg-violet-900 dark:text-violet-200' :
                        'bg-amber-100 text-amber-700 dark:bg-amber-900 dark:text-amber-200'}`}
                    >
                      {topic.topic}
                    </span>
                    <span className="text-sm text-muted-foreground">
                      {topic.count} questions from {topic.studentCount} students
                      ({Math.round((topic.studentCount / reports.totalStudents) * 100)}% participation)
                    </span>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>

          <Card className="bg-gradient-to-br from-slate-50 to-white dark:from-slate-900 dark:to-slate-800">
            <CardHeader>
              <CardTitle>Student Engagement Details</CardTitle>
            </CardHeader>
            <CardContent>
              <dl className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                <div className="rounded-lg bg-blue-50 p-4 dark:bg-blue-900/50">
                  <dt className="text-sm text-blue-700 dark:text-blue-200">
                    Average Messages per Student
                  </dt>
                  <dd className="text-2xl font-bold text-blue-900 dark:text-blue-50">
                    {agent.studentEngagement.averageMessagesPerStudent}
                  </dd>
                </div>
                <div className="rounded-lg bg-emerald-50 p-4 dark:bg-emerald-900/50">
                  <dt className="text-sm text-emerald-700 dark:text-emerald-200">
                    Average Session Duration
                  </dt>
                  <dd className="text-2xl font-bold text-emerald-900 dark:text-emerald-50">
                    {agent.studentEngagement.averageSessionDuration} min
                  </dd>
                </div>
                <div className="rounded-lg bg-violet-50 p-4 dark:bg-violet-900/50">
                  <dt className="text-sm text-violet-700 dark:text-violet-200">
                    Task Completion Rate
                  </dt>
                  <dd className="text-2xl font-bold text-violet-900 dark:text-violet-50">
                    {agent.studentEngagement.completionRate}%
                  </dd>
                </div>
              </dl>
            </CardContent>
          </Card>
        </div>
      ))}
    </div>
  )
}