import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

export interface Agent {
  id: string
  name: string
  description: string
  avatar?: string
  isActive: boolean
  microFrontendUrl?: string
}

export interface Organization {
  id: string
  name: string
  agents: Agent[]
  stats: {
    studentCount: number
    teamMemberCount: number
    totalChats: number
  }
  settings: {
    theme: 'light' | 'dark' | 'system'
    allowedDomains?: string[]
  }
}

interface OrgState {
  currentOrg: Organization
  setCurrentOrg: (org: Organization) => void
  updateOrgSettings: (settings: Partial<Organization['settings']>) => void
  toggleAgentStatus: (agentId: string) => void
}

const defaultOrg: Organization = {
  id: 'default-org',
  name: 'Botnexus.ai',
  agents: [
    {
      id: 'agent-1',
      name: 'General Assistant',
      description: 'A general-purpose AI assistant for everyday tasks',
      isActive: true,
      microFrontendUrl: 'https://botnexus-agents-chat.netlify.app/embed/general'
    },
    {
      id: 'agent-2',
      name: 'Code Helper',
      description: 'Specialized in helping with code and development',
      isActive: true,
      microFrontendUrl: 'https://botnexus-agents-chat.netlify.app/embed/code'
    },
    {
      id: 'agent-3',
      name: 'Data Analyst',
      description: 'Helps with data analysis and visualization',
      isActive: true,
      microFrontendUrl: 'https://botnexus-agents-chat.netlify.app/embed/data'
    },
    {
      id: 'agent-4',
      name: 'ITSwitcher Curator',
      description: 'Specialized in IT career transitions and guidance',
      isActive: true,
      microFrontendUrl: 'https://botnexus-agents-chat.netlify.app/embed/itswitcher'
    },
    {
      id: 'agent-5',
      name: 'Prognosa Bot',
      description: 'Advanced analytics and predictive insights',
      isActive: true,
      microFrontendUrl: 'https://botnexus-agents-chat.netlify.app/embed/prognosa'
    },
    {
      id: 'agent-6',
      name: 'Botnexus Cofounder',
      description: 'Strategic advisor for startup and business development',
      isActive: true,
      microFrontendUrl: 'https://botnexus-agents-chat.netlify.app/embed/cofounder'
    }
  ],
  stats: {
    studentCount: 156,
    teamMemberCount: 12,
    totalChats: 573,
  },
  settings: {
    theme: 'system',
  },
}

export const useOrgStore = create<OrgState>()(
  persist(
    (set) => ({
      currentOrg: defaultOrg,
      setCurrentOrg: (org) => set({ currentOrg: org }),
      updateOrgSettings: (settings) =>
        set((state) => ({
          currentOrg: {
            ...state.currentOrg,
            settings: { ...state.currentOrg.settings, ...settings },
          },
        })),
      toggleAgentStatus: (agentId) =>
        set((state) => ({
          currentOrg: {
            ...state.currentOrg,
            agents: state.currentOrg.agents.map((agent) =>
              agent.id === agentId
                ? { ...agent, isActive: !agent.isActive }
                : agent
            ),
          },
        })),
    }),
    {
      name: 'org-store',
      version: 1,
      storage: createJSONStorage(() => localStorage),
      migrate: (persistedState: any, version: number) => {
        if (version === 0) {
          return {
            ...persistedState,
            currentOrg: {
              ...defaultOrg,
              ...persistedState?.currentOrg,
            },
          }
        }
        return persistedState
      },
    }
  )
)