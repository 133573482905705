import { Outlet } from 'react-router-dom'
import { DashboardNav } from './DashboardNav'
import { DashboardHeader } from './DashboardHeader'
import { ScrollArea } from '../ui/scroll-area'
import { useState } from 'react'
import { MobileNav } from './MobileNav'

export function DashboardLayout() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  return (
    <div className="relative flex min-h-screen flex-col">
      <DashboardHeader>
        <MobileNav 
          isOpen={isMobileMenuOpen} 
          onOpenChange={setIsMobileMenuOpen} 
        />
      </DashboardHeader>
      
      <div className="flex flex-1">
        {/* Desktop Navigation */}
        <aside className="hidden w-64 border-r border-border bg-card md:flex">
          <ScrollArea className="flex w-full flex-col py-6">
            <div className="px-2 py-2">
              <h2 className="mb-2 px-4 text-lg font-semibold tracking-tight">
                Navigation
              </h2>
              <DashboardNav />
            </div>
          </ScrollArea>
        </aside>

        <main className="flex-1 overflow-hidden">
          <div className="container h-full py-6">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  )
}