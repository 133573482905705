import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Bot } from 'lucide-react'
import { useState } from 'react'
import { mockAgentStats } from '@/lib/data/mock-agent-stats'
import { ChatFrame } from '@/components/chat/ChatFrame'

export function ChatPage() {
  const [selectedAgentId, setSelectedAgentId] = useState<string | null>(null)

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-3xl font-bold tracking-tight">Chat</h2>
        <p className="text-muted-foreground mt-2">
          Select an AI agent to start chatting.
        </p>
      </div>

      <div className="space-y-6">
        {mockAgentStats.map((agent) => (
          <div key={agent.id}>
            <Card 
              className={`cursor-pointer transition-all hover:shadow-md ${
                selectedAgentId === agent.id ? 'ring-2 ring-primary' : ''
              }`}
              onClick={() => setSelectedAgentId(agent.id)}
            >
              <CardHeader>
                <CardTitle className="flex items-center gap-2 text-lg">
                  <Bot className="h-5 w-5" />
                  {agent.name}
                  <span
                    className={`ml-2 inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ${
                      agent.isActive
                        ? 'bg-green-100 text-green-700 dark:bg-green-900 dark:text-green-300'
                        : 'bg-gray-100 text-gray-700 dark:bg-gray-800 dark:text-gray-300'
                    }`}
                  >
                    {agent.isActive ? 'Active' : 'Inactive'}
                  </span>
                </CardTitle>
                <p className="text-sm text-muted-foreground">{agent.description}</p>
              </CardHeader>
              <CardContent className="space-y-6">
                <div className="grid gap-4 md:grid-cols-4">
                  <div className="rounded-lg bg-muted p-4">
                    <p className="text-sm font-medium text-muted-foreground">Total Users</p>
                    <p className="mt-2 text-2xl font-bold">{agent.usage.totalUsers}</p>
                  </div>
                  <div className="rounded-lg bg-muted p-4">
                    <p className="text-sm font-medium text-muted-foreground">Active Today</p>
                    <p className="mt-2 text-2xl font-bold">{agent.usage.activeToday}</p>
                  </div>
                  <div className="rounded-lg bg-muted p-4">
                    <p className="text-sm font-medium text-muted-foreground">Total Questions</p>
                    <p className="mt-2 text-2xl font-bold">{agent.usage.totalQuestions}</p>
                  </div>
                  <div className="rounded-lg bg-muted p-4">
                    <p className="text-sm font-medium text-muted-foreground">Questions Today</p>
                    <p className="mt-2 text-2xl font-bold">{agent.usage.questionsToday}</p>
                  </div>
                </div>
              </CardContent>
            </Card>

            {selectedAgentId === agent.id && (
              <Card className="mt-4">
                <CardHeader>
                  <CardTitle className="flex items-center gap-2">
                    <Bot className="h-5 w-5" />
                    Chat with {agent.name}
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <ChatFrame
                    agentUrl={agent.microFrontendUrl || "https://botnexus-agents-chat.netlify.app/"}
                    agentName={agent.name}
                  />
                </CardContent>
              </Card>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}