import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Brain, TrendingUp, Users, School } from 'lucide-react'
import { AgentReportData } from '@/lib/types/reports'

interface SchoolSummaryProps {
  data: AgentReportData
}

export function SchoolSummary({ data }: SchoolSummaryProps) {
  // Calculate overall metrics
  const totalQuestions = data.agents.reduce((sum, agent) => sum + agent.totalQuestions, 0)
  const averageAccuracy = data.agents.reduce((sum, agent) => sum + agent.aiEvaluation.accuracy, 0) / data.agents.length
  const participationRate = (data.activeStudents / data.totalStudents) * 100

  // Generate dynamic summary text
  const getSummaryText = () => {
    const performance = averageAccuracy > 90 ? 'exceptional' : averageAccuracy > 80 ? 'strong' : 'moderate'
    const engagement = participationRate > 80 ? 'high' : participationRate > 60 ? 'moderate' : 'growing'
    
    return `The learning platform is demonstrating ${performance} performance with ${engagement} student engagement. 
    Currently serving ${data.totalStudents} enrolled students, with ${data.activeStudents} actively participating 
    across ${data.agents.length} specialized AI agents. The system has facilitated ${totalQuestions} learning 
    interactions, maintaining an average accuracy rate of ${averageAccuracy.toFixed(1)}%.`
  }

  return (
    <Card className="bg-gradient-to-br from-indigo-50 via-purple-50 to-pink-50 dark:from-indigo-950 dark:via-purple-950 dark:to-pink-950">
      <CardHeader>
        <CardTitle className="flex items-center gap-2 text-xl">
          <Brain className="h-6 w-6 text-indigo-500" />
          AI Learning Platform Overview
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        <p className="text-muted-foreground leading-relaxed">
          {getSummaryText()}
        </p>

        <div className="grid gap-4 md:grid-cols-3">
          <div className="rounded-xl bg-white p-4 shadow-lg dark:bg-slate-800">
            <div className="flex items-center gap-2">
              <School className="h-5 w-5 text-indigo-500" />
              <h3 className="font-semibold text-indigo-600 dark:text-indigo-400">
                Learning Environment
              </h3>
            </div>
            <div className="mt-3 space-y-1">
              <p className="text-2xl font-bold">{data.agents.length} AI Agents</p>
              <p className="text-sm text-muted-foreground">
                Specialized Learning Assistants
              </p>
            </div>
          </div>

          <div className="rounded-xl bg-white p-4 shadow-lg dark:bg-slate-800">
            <div className="flex items-center gap-2">
              <Users className="h-5 w-5 text-purple-500" />
              <h3 className="font-semibold text-purple-600 dark:text-purple-400">
                Student Engagement
              </h3>
            </div>
            <div className="mt-3 space-y-1">
              <p className="text-2xl font-bold">{participationRate.toFixed(1)}%</p>
              <p className="text-sm text-muted-foreground">
                Active Participation Rate
              </p>
            </div>
          </div>

          <div className="rounded-xl bg-white p-4 shadow-lg dark:bg-slate-800">
            <div className="flex items-center gap-2">
              <TrendingUp className="h-5 w-5 text-pink-500" />
              <h3 className="font-semibold text-pink-600 dark:text-pink-400">
                Platform Performance
              </h3>
            </div>
            <div className="mt-3 space-y-1">
              <p className="text-2xl font-bold">{averageAccuracy.toFixed(1)}%</p>
              <p className="text-sm text-muted-foreground">
                Average AI Accuracy
              </p>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}