import { createContext, useContext, useEffect, useState } from 'react'
import { User } from '@supabase/supabase-js'
import { supabase } from '@/lib/supabase'
import { LoadingScreen } from '../ui/loading-screen'
import { config } from '@/lib/config'

interface AuthContextType {
  user: User | null
  loading: boolean
}

const AuthContext = createContext<AuthContextType>({ user: null, loading: true })

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Always use development mode in dev environment
    if (config.isDevelopment) {
      const now = new Date().toISOString()
      const devUser: User = {
        id: 'dev-user',
        email: 'dev@example.com',
        aud: 'authenticated',
        created_at: now,
        role: 'authenticated',
        app_metadata: {
          provider: 'email',
          providers: ['email'],
        },
        user_metadata: {
          full_name: 'Development User',
          avatar_url: null,
          user_type: 'student',
          signup_source: 'development',
          initial_login: now
        },
        identities: [],
        confirmation_sent_at: now,
        confirmed_at: now,
        last_sign_in_at: now,
        factors: [],
        phone: '',
        updated_at: now,
        recovery_sent_at: now,
        email_confirmed_at: now,
      }
      
      setUser(devUser)
      setLoading(false)
      return
    }

    // Get initial session
    supabase.auth.getSession().then(({ data: { session } }) => {
      const user = session?.user
      if (user) {
        console.log('Initial session user:', user)
        console.log('User metadata:', user.user_metadata)
        console.log('App metadata:', user.app_metadata)
      }
      setUser(user ?? null)
      setLoading(false)
    })

    // Listen for auth changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      const user = session?.user
      if (user) {
        console.log('Auth state changed:', _event)
        console.log('New user state:', user)
        console.log('User metadata:', user.user_metadata)
        console.log('App metadata:', user.app_metadata)
      }
      setUser(user ?? null)
      setLoading(false)
    })

    return () => subscription.unsubscribe()
  }, [])

  if (loading) {
    return <LoadingScreen />
  }

  return (
    <AuthContext.Provider value={{ user, loading }}>
      {children}
    </AuthContext.Provider>
  )
}