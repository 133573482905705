import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Bot } from 'lucide-react'
import { useOrgStore } from '@/lib/store/org-store'
import { AgentUsageStats } from '@/components/agents/AgentUsageStats'
import { AgentGrowthChart } from '@/components/agents/AgentGrowthChart'
import { mockAgentStats } from '@/lib/data/mock-agent-stats'

export function AgentsPage() {
  const org = useOrgStore((state) => state.currentOrg)
  const activeAgents = org.agents.filter((agent) => agent.isActive)

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-3xl font-bold tracking-tight">Agents</h2>
        <p className="text-muted-foreground mt-2">
          Manage and monitor AI agents performance.
        </p>
      </div>

      <Card>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Active Agents</CardTitle>
          <Bot className="h-4 w-4 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">{activeAgents.length}</div>
        </CardContent>
      </Card>

      <div className="space-y-6">
        {mockAgentStats.map((agent) => (
          <div key={agent.id} className="space-y-4">
            <Card>
              <CardHeader>
                <CardTitle className="flex items-center gap-2 text-lg">
                  <Bot className="h-5 w-5" />
                  {agent.name}
                  <span
                    className={`ml-2 inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ${
                      agent.isActive
                        ? 'bg-green-100 text-green-700 dark:bg-green-900 dark:text-green-300'
                        : 'bg-gray-100 text-gray-700 dark:bg-gray-800 dark:text-gray-300'
                    }`}
                  >
                    {agent.isActive ? 'Active' : 'Inactive'}
                  </span>
                </CardTitle>
                <p className="text-sm text-muted-foreground">{agent.description}</p>
              </CardHeader>
              <CardContent className="space-y-6">
                <AgentUsageStats {...agent.usage} />
                <AgentGrowthChart data={agent.growth} />
              </CardContent>
            </Card>
          </div>
        ))}
      </div>
    </div>
  )
}