import { useCallback, useEffect, useRef } from 'react'
import { useApiKeys } from './useApiKeys'
import { useAuth } from '@/components/auth/AuthProvider'

export function useIframeMessaging(iframeRef: React.RefObject<HTMLIFrameElement>) {
  const { keys } = useApiKeys()
  const { user } = useAuth()
  const hasConfigured = useRef(false)

  const sendConfig = useCallback(() => {
    if (!iframeRef.current?.contentWindow || !keys.nexusHost || !keys.nexusSecret) {
      return
    }

    const config = {
      type: 'CONFIG_UPDATE',
      payload: {
        api: keys.nexusHost,
        secret: keys.nexusSecret,
        context: {
          userId: user?.id,
          email: user?.email,
          metadata: user?.user_metadata
        }
      }
    }

    iframeRef.current.contentWindow.postMessage(config, '*')
    hasConfigured.current = true
  }, [keys.nexusHost, keys.nexusSecret, user])

  useEffect(() => {
    const handleLoad = () => {
      if (!hasConfigured.current) {
        sendConfig()
      }
    }

    const iframe = iframeRef.current
    iframe?.addEventListener('load', handleLoad)

    return () => {
      iframe?.removeEventListener('load', handleLoad)
    }
  }, [sendConfig, iframeRef])

  return { sendConfig }
}