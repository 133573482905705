import { Routes, Route, Navigate } from 'react-router-dom'
import { DashboardLayout } from '../layout/DashboardLayout'
import { DashboardPage } from '@/pages/dashboard/DashboardPage'
import { StudentsPage } from '@/pages/students/StudentsPage'
import { ReportsPage } from '@/pages/reports/ReportsPage'
import { ChatsPage } from '@/pages/chats/ChatsPage'
import { ChatPage } from '@/pages/chat/ChatPage'
import { AgentsPage } from '@/pages/agents/AgentsPage'
import { TeamPage } from '@/pages/team/TeamPage'
import { SettingsPage } from '@/pages/settings/SettingsPage'
import { LoginPage } from '@/pages/auth/LoginPage'
import { SignupPage } from '@/pages/auth/SignupPage'
import { ErrorBoundary } from './ErrorBoundary'
import { useAuth } from './AuthProvider'

function PrivateRoute({ children }: { children: React.ReactNode }) {
  const { user, loading } = useAuth()

  if (loading) return null

  if (!user) {
    return <Navigate to="/login" replace />
  }

  return <>{children}</>
}

export function AppRoutes() {
  const { user } = useAuth()

  return (
    <ErrorBoundary>
      <Routes>
        <Route path="/login" element={
          user ? <Navigate to="/dashboard" replace /> : <LoginPage />
        } />
        <Route path="/signup" element={
          user ? <Navigate to="/dashboard" replace /> : <SignupPage />
        } />
        
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        
        <Route element={
          <PrivateRoute>
            <DashboardLayout />
          </PrivateRoute>
        }>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/dashboard/chat" element={<ChatPage />} />
          <Route path="/dashboard/chats" element={<ChatsPage />} />
          <Route path="/dashboard/agents" element={<AgentsPage />} />
          <Route path="/dashboard/students" element={<StudentsPage />} />
          <Route path="/dashboard/reports" element={<ReportsPage />} />
          <Route path="/dashboard/team" element={<TeamPage />} />
          <Route path="/dashboard/settings" element={<SettingsPage />} />
        </Route>

        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </ErrorBoundary>
  )
}