import { useRef } from 'react'
import { useIframeMessaging } from '@/lib/hooks/useIframeMessaging'
import { useApiKeys } from '@/lib/hooks/useApiKeys'
import { Card, CardContent } from '../ui/card'
import { Alert, AlertDescription } from '../ui/alert'
import { AlertCircle } from 'lucide-react'
import { Button } from '../ui/button'
import { Link } from 'react-router-dom'

interface ChatFrameProps {
  agentUrl: string
  agentName: string
}

export function ChatFrame({ agentUrl, agentName }: ChatFrameProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const { keys } = useApiKeys()
  useIframeMessaging(iframeRef) // Just use the hook without destructuring

  const hasApiConfig = keys.nexusHost && keys.nexusSecret

  if (!hasApiConfig) {
    return (
      <Card>
        <CardContent className="pt-6">
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription className="flex items-center justify-between">
              <span>API configuration is required to chat with agents.</span>
              <Button variant="outline" asChild>
                <Link to="/dashboard/settings">Configure API</Link>
              </Button>
            </AlertDescription>
          </Alert>
        </CardContent>
      </Card>
    )
  }

  return (
    <div className="h-[600px] rounded-lg border bg-background overflow-hidden">
      <iframe
        ref={iframeRef}
        src={agentUrl}
        className="h-full w-full border-none"
        title={`Chat with ${agentName}`}
        allow="microphone; camera"
        sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-modals"
        loading="lazy"
        referrerPolicy="origin"
      />
    </div>
  )
}