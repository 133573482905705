import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { useState } from 'react'

interface SecretInputProps {
  id: string
  value: string
  onChange: (value: string) => void
  placeholder: string
  label: string
}

export function SecretInput({
  id,
  value,
  onChange,
  placeholder,
  label,
}: SecretInputProps) {
  const [showSecret, setShowSecret] = useState(false)

  return (
    <div className="space-y-2">
      <label htmlFor={id} className="text-sm font-medium">
        {label}
      </label>
      <div className="flex gap-2">
        <Input
          id={id}
          type={showSecret ? 'text' : 'password'}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
        />
        <Button
          variant="outline"
          type="button"
          onClick={() => setShowSecret(!showSecret)}
        >
          {showSecret ? 'Hide' : 'Show'}
        </Button>
      </div>
    </div>
  )
}