import { AgentReportData } from '../types/reports'

export const mockAgentReports: AgentReportData = {
  totalStudents: 500,
  activeStudents: 425,
  agents: [
    {
      agentId: 'agent-1',
      agentName: 'General Assistant',
      totalQuestions: 1250,
      uniqueStudents: 380,
      averageResponseTime: 1.8,
      questionTypes: [
        { type: 'Conceptual', count: 450, percentage: 36, uniqueStudents: 280 },
        { type: 'Problem Solving', count: 375, percentage: 30, uniqueStudents: 245 },
        { type: 'Clarification', count: 250, percentage: 20, uniqueStudents: 190 },
        { type: 'Application', count: 175, percentage: 14, uniqueStudents: 150 },
      ],
      aiEvaluation: {
        accuracy: 92,
        helpfulness: 88,
        clarity: 90,
        responseTime: 95,
        totalStudents: 380,
        activeStudents: 325
      },
      topTopics: [
        { topic: 'Data Structures', count: 250, studentCount: 180 },
        { topic: 'Algorithms', count: 200, studentCount: 165 },
        { topic: 'System Design', count: 175, studentCount: 145 },
        { topic: 'Web Development', count: 150, studentCount: 120 },
        { topic: 'Database Management', count: 125, studentCount: 100 },
      ],
      studentEngagement: {
        totalStudents: 380,
        activeStudents: 325,
        averageMessagesPerStudent: 12,
        averageSessionDuration: 15.5,
        completionRate: 85,
        participationRate: 85.5
      }
    },
    {
      agentId: 'agent-2',
      agentName: 'Code Helper',
      totalQuestions: 980,
      uniqueStudents: 290,
      averageResponseTime: 2.1,
      questionTypes: [
        { type: 'Code Review', count: 400, percentage: 41, uniqueStudents: 220 },
        { type: 'Debugging', count: 300, percentage: 31, uniqueStudents: 195 },
        { type: 'Best Practices', count: 180, percentage: 18, uniqueStudents: 150 },
        { type: 'Setup Help', count: 100, percentage: 10, uniqueStudents: 95 },
      ],
      aiEvaluation: {
        accuracy: 94,
        helpfulness: 91,
        clarity: 89,
        responseTime: 92,
        totalStudents: 290,
        activeStudents: 245
      },
      topTopics: [
        { topic: 'JavaScript', count: 300, studentCount: 200 },
        { topic: 'Python', count: 250, studentCount: 175 },
        { topic: 'React', count: 200, studentCount: 150 },
        { topic: 'Node.js', count: 150, studentCount: 120 },
        { topic: 'TypeScript', count: 100, studentCount: 85 },
      ],
      studentEngagement: {
        totalStudents: 290,
        activeStudents: 245,
        averageMessagesPerStudent: 14,
        averageSessionDuration: 18.2,
        completionRate: 88,
        participationRate: 84.5
      }
    }
  ]
}