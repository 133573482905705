import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Settings } from 'lucide-react'
import { useOrgStore } from '@/lib/store/org-store'
import { Button } from '@/components/ui/button'
import { ProfileSection } from './ProfileSection'
import { ApiKeysSection } from './ApiKeysSection'
import { supabase } from '@/lib/supabase'
import { useNavigate } from 'react-router-dom'

export function SettingsPage() {
  const navigate = useNavigate()
  const { currentOrg, updateOrgSettings } = useOrgStore()

  const toggleTheme = () => {
    const newTheme = currentOrg.settings.theme === 'dark' ? 'light' : 'dark'
    updateOrgSettings({ theme: newTheme })
    document.documentElement.classList.toggle('dark')
  }

  const handleSignOut = async () => {
    await supabase.auth.signOut()
    navigate('/login')
  }

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-3xl font-bold tracking-tight">Settings</h2>
        <p className="text-muted-foreground mt-2">
          Manage your profile, organization settings and preferences.
        </p>
      </div>
      <div className="grid gap-4">
        <ProfileSection />
        
        <ApiKeysSection />
        
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0">
            <CardTitle className="text-lg font-medium">Appearance</CardTitle>
            <Settings className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div>
                  <p className="font-medium">Theme</p>
                  <p className="text-sm text-muted-foreground">
                    Switch between light and dark mode
                  </p>
                </div>
                <Button onClick={toggleTheme} variant="outline">
                  {currentOrg.settings.theme === 'dark' ? 'Light' : 'Dark'} Mode
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Organization Settings</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div>
                <p className="font-medium">Organization Name</p>
                <p className="text-sm text-muted-foreground">
                  {currentOrg.name}
                </p>
              </div>
              <div>
                <p className="font-medium">Organization ID</p>
                <p className="text-sm text-muted-foreground">
                  {currentOrg.id}
                </p>
              </div>
              <Button 
                variant="destructive" 
                onClick={handleSignOut}
                className="mt-4"
              >
                Sign Out
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}