import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { QuestionType } from '@/lib/types/reports'

const COLORS = [
  'from-violet-500 to-violet-600',
  'from-blue-500 to-blue-600',
  'from-emerald-500 to-emerald-600',
  'from-amber-500 to-amber-600',
]

interface QuestionTypeChartProps {
  data: QuestionType[]
  totalStudents: number
}

export function QuestionTypeChart({ data, totalStudents }: QuestionTypeChartProps) {
  return (
    <Card className="col-span-2 bg-gradient-to-br from-slate-50 to-white dark:from-slate-900 dark:to-slate-800">
      <CardHeader>
        <CardTitle>Question Types Distribution</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {data.map((item, index) => (
            <div key={item.type} className="space-y-2">
              <div className="flex justify-between text-sm">
                <span className="font-medium">{item.type}</span>
                <div className="space-x-4">
                  <span className="text-muted-foreground">
                    {item.uniqueStudents} students ({Math.round(item.uniqueStudents/totalStudents * 100)}%)
                  </span>
                  <span className="font-medium">{item.percentage}% of questions</span>
                </div>
              </div>
              <div className="h-3 rounded-full bg-slate-200 dark:bg-slate-700">
                <div
                  className={`h-full rounded-full bg-gradient-to-r ${COLORS[index % COLORS.length]} transition-all`}
                  style={{ width: `${item.percentage}%` }}
                />
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  )
}