import { cn } from "@/lib/utils"

interface LogoProps {
  className?: string
  showText?: boolean
}

export function Logo({ className, showText = true }: LogoProps) {
  return (
    <div className={cn("flex items-center gap-2", className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="h-8 w-8"
      >
        <defs>
          <linearGradient id="orangeVioletGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#FFA500" />
            <stop offset="100%" stopColor="#8A2BE2" />
          </linearGradient>
        </defs>
        <path d="M12 8V4H8" stroke="url(#orangeVioletGradient)" />
        <rect width="16" height="12" x="4" y="8" rx="2" stroke="url(#orangeVioletGradient)" />
        <rect x="1" y="12" width="3" height="3" fill="#FFA500" />
        <rect x="20" y="12" width="3" height="3" fill="#8A2BE2" />
        <line x1="15" y1="13" x2="15" y2="15" stroke="currentColor" />
        <line x1="9" y1="13" x2="9" y2="15" stroke="currentColor" />
      </svg>
      {showText && (
        <span className="text-xl font-bold hidden sm:inline">
          Botnexus
        </span>
      )}
    </div>
  )
}