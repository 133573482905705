import { subDays } from 'date-fns'

export interface AgentStats {
  id: string
  name: string
  description: string
  isActive: boolean
  microFrontendUrl?: string
  usage: {
    totalUsers: number
    activeToday: number
    totalQuestions: number
    questionsToday: number
  }
  growth: Array<{
    date: string
    questions: number
  }>
}

// Helper function to generate random growth data
function generateGrowthData() {
  return Array.from({ length: 30 }, (_, i) => ({
    date: subDays(new Date(), 29 - i).toISOString(),
    questions: Math.floor(Math.random() * 500) + 100,
  }))
}

export const mockAgentStats: AgentStats[] = [
  {
    id: 'agent-1',
    name: 'General Assistant',
    description: 'A general-purpose AI assistant for everyday tasks',
    isActive: true,
    microFrontendUrl: 'https://botnexus-agents-chat.netlify.app/embed/general',
    usage: {
      totalUsers: 1250,
      activeToday: 342,
      totalQuestions: 15780,
      questionsToday: 1243
    },
    growth: generateGrowthData()
  },
  {
    id: 'agent-2',
    name: 'Code Helper',
    description: 'Specialized in helping with code and development',
    isActive: true,
    microFrontendUrl: 'https://botnexus-agents-chat.netlify.app/embed/code',
    usage: {
      totalUsers: 980,
      activeToday: 256,
      totalQuestions: 12450,
      questionsToday: 986
    },
    growth: generateGrowthData()
  },
  {
    id: 'agent-3',
    name: 'Data Analyst',
    description: 'Helps with data analysis and visualization',
    isActive: false,
    microFrontendUrl: 'https://botnexus-agents-chat.netlify.app/embed/data',
    usage: {
      totalUsers: 645,
      activeToday: 178,
      totalQuestions: 8920,
      questionsToday: 745
    },
    growth: generateGrowthData()
  },
  {
    id: 'agent-4',
    name: 'ITSwitcher Curator',
    description: 'Specialized in IT career transitions and guidance',
    isActive: true,
    microFrontendUrl: 'https://botnexus-agents-chat.netlify.app/embed/itswitcher',
    usage: {
      totalUsers: 890,
      activeToday: 234,
      totalQuestions: 10560,
      questionsToday: 876
    },
    growth: generateGrowthData()
  },
  {
    id: 'agent-5',
    name: 'Prognosa Bot',
    description: 'Advanced analytics and predictive insights',
    isActive: true,
    microFrontendUrl: 'https://botnexus-agents-chat.netlify.app/embed/prognosa',
    usage: {
      totalUsers: 765,
      activeToday: 198,
      totalQuestions: 9870,
      questionsToday: 654
    },
    growth: generateGrowthData()
  },
  {
    id: 'agent-6',
    name: 'Botnexus Cofounder',
    description: 'Strategic advisor for startup and business development',
    isActive: true,
    microFrontendUrl: 'https://botnexus-agents-chat.netlify.app/embed/cofounder',
    usage: {
      totalUsers: 567,
      activeToday: 145,
      totalQuestions: 7890,
      questionsToday: 432
    },
    growth: generateGrowthData()
  }
]