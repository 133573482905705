import { format } from 'date-fns'

export interface Student {
  id: string
  dateAdded: string
  name: string
  active: boolean
  queries: number
  tokens: number
  bots: string[]
  secret: string
}

export const mockStudents: Student[] = Array.from({ length: 50 }, (_, i) => ({
  id: `student-${i + 1}`,
  dateAdded: format(new Date(2023, 0, 1 + i), 'yyyy-MM-dd'),
  name: `Student ${i + 1}`,
  active: Math.random() > 0.3,
  queries: Math.floor(Math.random() * 1000),
  tokens: Math.floor(Math.random() * 100000),
  bots: Array.from(
    { length: Math.floor(Math.random() * 3) + 1 },
    (_, j) => `Bot ${j + 1}`
  ),
  secret: `sk-${crypto.randomUUID().split('-')[0]}`,
}))