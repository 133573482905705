import { Card, CardContent } from '@/components/ui/card'
import { Users, MessageSquare, Activity } from 'lucide-react'

interface AgentUsageStatsProps {
  totalUsers: number
  activeToday: number
  totalQuestions: number
  questionsToday: number
}

export function AgentUsageStats({ 
  totalUsers, 
  activeToday, 
  totalQuestions, 
  questionsToday 
}: AgentUsageStatsProps) {
  return (
    <div className="grid gap-4 md:grid-cols-4">
      <Card className="bg-gradient-to-br from-blue-50 to-blue-100 dark:from-blue-950 dark:to-blue-900">
        <CardContent className="pt-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-blue-600 dark:text-blue-300">Total Users</p>
              <p className="text-2xl font-bold text-blue-700 dark:text-blue-200">{totalUsers}</p>
            </div>
            <Users className="h-8 w-8 text-blue-500" />
          </div>
        </CardContent>
      </Card>

      <Card className="bg-gradient-to-br from-emerald-50 to-emerald-100 dark:from-emerald-950 dark:to-emerald-900">
        <CardContent className="pt-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-emerald-600 dark:text-emerald-300">Active Today</p>
              <p className="text-2xl font-bold text-emerald-700 dark:text-emerald-200">{activeToday}</p>
            </div>
            <Activity className="h-8 w-8 text-emerald-500" />
          </div>
        </CardContent>
      </Card>

      <Card className="bg-gradient-to-br from-violet-50 to-violet-100 dark:from-violet-950 dark:to-violet-900">
        <CardContent className="pt-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-violet-600 dark:text-violet-300">Total Questions</p>
              <p className="text-2xl font-bold text-violet-700 dark:text-violet-200">{totalQuestions}</p>
            </div>
            <MessageSquare className="h-8 w-8 text-violet-500" />
          </div>
        </CardContent>
      </Card>

      <Card className="bg-gradient-to-br from-amber-50 to-amber-100 dark:from-amber-950 dark:to-amber-900">
        <CardContent className="pt-4">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-medium text-amber-600 dark:text-amber-300">Questions Today</p>
              <p className="text-2xl font-bold text-amber-700 dark:text-amber-200">{questionsToday}</p>
            </div>
            <MessageSquare className="h-8 w-8 text-amber-500" />
          </div>
        </CardContent>
      </Card>
    </div>
  )
}