import { Link, useLocation } from 'react-router-dom'
import { cn } from '@/lib/utils'
import {
  LayoutDashboard,
  MessageSquare,
  Settings,
  Users,
  Bot,
  GraduationCap,
  BarChart3,
  MessagesSquare,
} from 'lucide-react'

interface DashboardNavProps {
  onNavClick?: () => void
}

const navItems = [
  {
    title: 'Dashboard',
    href: '/dashboard',
    icon: LayoutDashboard,
  },
  {
    title: 'Chat',
    href: '/dashboard/chat',
    icon: MessagesSquare,
  },
  {
    title: 'Chats',
    href: '/dashboard/chats',
    icon: MessageSquare,
  },
  {
    title: 'Agents',
    href: '/dashboard/agents',
    icon: Bot,
  },
  {
    title: 'Students',
    href: '/dashboard/students',
    icon: GraduationCap,
  },
  {
    title: 'Reports',
    href: '/dashboard/reports',
    icon: BarChart3,
  },
  {
    title: 'Team',
    href: '/dashboard/team',
    icon: Users,
  },
  {
    title: 'Settings',
    href: '/dashboard/settings',
    icon: Settings,
  },
]

export function DashboardNav({ onNavClick }: DashboardNavProps) {
  const location = useLocation()

  return (
    <nav className="grid gap-1 px-2">
      {navItems.map((item) => (
        <Link
          key={item.href}
          to={item.href}
          onClick={onNavClick}
          className={cn(
            'menu-item',
            location.pathname === item.href && 'active'
          )}
        >
          <item.icon className="menu-item-icon" />
          <span>{item.title}</span>
        </Link>
      ))}
    </nav>
  )
}