import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Bot } from 'lucide-react'
import { cn } from '@/lib/utils'

interface AgentMetricsCardProps {
  agentName: string
  metrics: {
    label: string
    value: string | number
    description?: string
    type?: 'default' | 'success' | 'warning' | 'info'
  }[]
}

export function AgentMetricsCard({ agentName, metrics }: AgentMetricsCardProps) {
  return (
    <Card className="col-span-full bg-gradient-to-br from-slate-50 to-white dark:from-slate-900 dark:to-slate-800">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="flex items-center gap-2">
          <Bot className="h-5 w-5 text-blue-500" />
          {agentName}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid gap-4 md:grid-cols-4">
          {metrics.map((metric) => {
            const colors = {
              default: 'from-slate-500 to-slate-600',
              success: 'from-emerald-500 to-emerald-600',
              warning: 'from-amber-500 to-amber-600',
              info: 'from-blue-500 to-blue-600',
            }
            
            return (
              <div 
                key={metric.label} 
                className={cn(
                  "space-y-1 rounded-lg p-4",
                  "bg-gradient-to-br",
                  colors[metric.type || 'default'],
                  "text-white shadow-lg"
                )}
              >
                <p className="text-sm opacity-90">{metric.label}</p>
                <p className="text-2xl font-bold">{metric.value}</p>
                {metric.description && (
                  <p className="text-xs opacity-75">{metric.description}</p>
                )}
              </div>
            )
          })}
        </div>
      </CardContent>
    </Card>
  )
}