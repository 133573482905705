import { useMemo } from 'react'
import { useOrgStore } from '@/lib/store/org-store'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { GraduationCap } from 'lucide-react'
import { AgGridReact } from 'ag-grid-react'
import { ColDef } from 'ag-grid-community'
import { mockStudents, Student } from '@/lib/data/mock-students'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'

export function StudentsPage() {
  const org = useOrgStore((state) => state.currentOrg)

  const columnDefs = useMemo<ColDef<Student>[]>(
    () => [
      {
        field: 'dateAdded',
        headerName: 'Date Added',
        sortable: true,
        filter: true,
      },
      {
        field: 'name',
        headerName: 'Name',
        sortable: true,
        filter: true,
      },
      {
        field: 'active',
        headerName: 'Status',
        sortable: true,
        filter: true,
        cellRenderer: (params: { value: boolean }) => (
          <span
            className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ${
              params.value
                ? 'bg-green-100 text-green-700 dark:bg-green-900 dark:text-green-300'
                : 'bg-red-100 text-red-700 dark:bg-red-900 dark:text-red-300'
            }`}
          >
            {params.value ? 'Active' : 'Inactive'}
          </span>
        ),
      },
      {
        field: 'queries',
        headerName: 'Queries',
        sortable: true,
        filter: true,
      },
      {
        field: 'tokens',
        headerName: 'Tokens',
        sortable: true,
        filter: true,
      },
      {
        field: 'bots',
        headerName: 'Bots',
        sortable: true,
        filter: true,
        cellRenderer: (params: { value: string[] }) => (
          <div className="flex gap-1">
            {params.value.map((bot) => (
              <span
                key={bot}
                className="inline-flex items-center rounded-full bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700 dark:bg-blue-900 dark:text-blue-300"
              >
                {bot}
              </span>
            ))}
          </div>
        ),
      },
      {
        field: 'secret',
        headerName: 'Secret',
        sortable: true,
        filter: true,
        cellRenderer: (params: { value: string }) => (
          <code className="rounded bg-muted px-2 py-1">{params.value}</code>
        ),
      },
    ],
    []
  )

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      flex: 1,
      minWidth: 100,
    }),
    []
  )

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-3xl font-bold tracking-tight">Students</h2>
        <p className="text-muted-foreground mt-2">
          Manage and monitor student progress and engagement.
        </p>
      </div>
      <div className="grid gap-4 md:grid-cols-3">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Total Students</CardTitle>
            <GraduationCap className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{org.stats.studentCount}</div>
          </CardContent>
        </Card>
      </div>
      <Card>
        <CardHeader>
          <CardTitle>Student List</CardTitle>
        </CardHeader>
        <CardContent>
          <div
            className="ag-theme-alpine dark:ag-theme-alpine-dark"
            style={{ height: 600 }}
          >
            <AgGridReact
              rowData={mockStudents}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              pagination={true}
              paginationPageSize={10}
              animateRows={true}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  )
}